 
 body{
  font-family: Poppins;
 }
 @media screen and (min-width: 1400px) {
  body .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    width: 1200px;
  }
}
 h1, h2, h3, h4, h5, h6, div, ul, ol, li, a, span{
  font-family: Poppins;
 }
 .header{}
 .header nav{
  background: #FFF;
box-shadow: 0px 6px 8px 0px rgba(131, 126, 255, 0.10);
padding: 15px 0 16px;
 }
 .header nav.fixed-top{
transition: .3s all ease;
top:-300px;
 }
 .header nav.fixed-top.active_div{
  top:0;
 }
 .header nav img{
  width:240px;
  position: relative;
  z-index: 2;
 }
 .navbar-toggler{
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  right:0;
  top:0;
  border: none !important;
  width: 38px;
  height:33px;
}
.navbar .navbar-toggler:focus{
box-shadow: none;
}
.navbar [role=button]{

}
.navbar-toggler input {
  position: fixed;
  opacity: 0;
  right:28px;
  top:0;
  width: 28px;
  height: 28px;
}

.navbar-toggler label {
  width: 40px;
  height: 33px;
  /* background: #ddd; */
  margin: -3px 0 0 -12px;
  cursor: pointer;
}
.navbar-toggler label::before {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.navbar-toggler label .burger {
  /* position: relative; */

}
.navbar-toggler label .burger::before,
.navbar-toggler label .burger .bar, label .burger::after {
  position: absolute;
  left: 0;
  display: block;
  width: 100%;
  height: 14%;
  background: #000;
  content: "";
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.navbar-toggler label .burger .bar {
  top: 44%;

}
.navbar-toggler label .burger::before {
  top: 0;
  transform-origin: top left;
}
.navbar-toggler label .burger::after {
  bottom: 0;
  transform-origin: bottom left;
}
.navbar-toggler label .visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.navbar-toggler input:focus + label .burger,
.navbar-toggler label:hover .burger {

}

header.header nav ul li a{
color: #1F2933;
text-align: right;
font-size: 64px;
font-style: normal;
font-weight: 400;
line-height: 130%; /* 83.2px */
text-decoration: none;
display: block;
margin-bottom: 0;
}
header.header nav ul li a:hover{
}

nav a:nth-child(1) {
  transition-delay: 0s, 100ms;
}
nav a:nth-child(2) {
  transition-delay: 0s, 150ms;
}
nav a:nth-child(3) {
  transition-delay: 0s, 200ms;
}
nav a:nth-child(4) {
  transition-delay: 0s, 250ms;
}
nav a:nth-child(5) {
  transition-delay: 0s, 300ms;
}
nav a:nth-child(6) {
  transition-delay: 0s, 350ms;
}
nav a:hover, nav a:focus {
  color: #1950FF;
}


[id=navcheck]:checked + label::before {
  opacity: 1;
  pointer-events: auto;
  
}
[id=navcheck]:checked + label .burger::before, [id=navcheck]:checked + label .burger::after {
  width: 100%;
}
[id=navcheck]:checked + label .burger::before {
  transform: rotate(45deg) translateY(-50%);
}
[id=navcheck]:checked + label .burger::before {
  top: 6.5px;
}
[id=navcheck]:checked + label .burger::after {
  transform: rotate(-45deg) translateY(50%);
}
[id=navcheck]:checked + label .burger .bar {
  transform: scale(0.1);
}
[id=navcheck]:checked ~ nav {
  transform: translateX(0);
}
[id=navcheck]:checked ~ nav::before {
  transform: skewX(15deg) translateX(0);
}
[id=navcheck]:checked ~ nav a {
  transform: translateX(0);
}
[id=navcheck]:checked ~ main .content {
  transform: translateX(3em);
  transform-origin: left center;
  filter: blur(2px);
}

.navbar-collapse{

}
.navbar-collapse ul{
  text-align: right;
  padding: 45px 0 25px;
  position: relative;
  z-index: 1;
}
.navbar-collapse ul li{
 margin-bottom:20px;
}
.page_banner_wrapper{
  background: linear-gradient(104deg, #E8FDFF 0%, #E8FDFF 100%);
  padding:10rem 0 7rem;
}
.page_banner_wrapper .page_banner_form{
  background: #FFF;
  box-shadow: 0px 4px 14px 0px rgba(131, 126, 255, 0.24);
  padding: 48px;
}
.page_banner_wrapper .page_banner_form h5{
  color: #737E80;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 21px */
letter-spacing: 0.56px;
text-transform: uppercase;
}
.page_banner_wrapper .page_banner_form h1{
  color: #1F3133;
font-size: 41px;
font-style: normal;
font-weight: 600;
line-height: 140%; /* 57.4px */
letter-spacing: -0.615px;
 margin-bottom:32px
}
.page_banner_wrapper .page_banner_form h1 span{
  color: #00A6BA;
}
.page_banner_content{
  padding-left: 10px;
}
.page_banner_content h3{
  color: #1F3133;
font-family: Poppins;
font-size: 58px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 69.6px */
letter-spacing: -1.45px;
margin-bottom: 64px;
}
.page_banner_content h3 span{
  color: #00A6BA;
}
.why_box_wrapper{
  display: flex;
  flex-wrap: wrap;
}

.why_box_wrapper .why_box_item:nth-child(3) {
  margin-bottom: 0;
}
.why_box_wrapper .why_box_item:nth-child(4) {
  margin-bottom: 0;
}
.why_box_wrapper .why_box_item{
  flex: 1;
  min-width: 0; /* Allow items to shrink */
  box-sizing: border-box;
  padding: 5px; /* Adjust margin as needed */
  margin-bottom: 48px;
}

/* For the first two items in the first row */
.why_box_wrapper .why_box_item:nth-child(-n+2) {
  flex-basis: calc(50% - 10px); /* 50% width with margins */
}

/* For the last two items in the second row */
.why_box_wrapper .why_box_item:nth-last-child(-n+2) {
  flex-basis: calc(50% - 10px); /* 50% width with margins */
}


.why_box_item img, .why_box_item svg{
  margin-bottom: 16px;
     color: #00A6BA;
}
.why_box_item h6{
  color: #1F3133;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 140%; 
letter-spacing: -0.15px;
}
.why_box_item p{
  color: #737E80;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; 
  letter-spacing: -0.09px;
}


.pure-material-textfield-filled {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 1.5;
  /* overflow: hidden; */
  width:100%;
  margin-bottom: 22px;
}
.pure-material-textfield-filled svg{
  position: absolute;
  top: 18px;
  left: 16px;
  font-size: 22px;
  color: #858585;
}

/* Input, Textarea */
.pure-material-textfield-filled > input {
  display: block;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  border: none;
  padding:10px 12px 0px 56px;
  width: 100%;
  height: 60px;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.04);
  background-color: #F5F5F5;
  box-shadow: none; /* Firefox */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  caret-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  transition: border-bottom 0.2s, background-color 0.2s;
  border: 2px solid #F5F5F5;
  
}

/* Span */
.pure-material-textfield-filled > input + span {
  position: absolute;
  top: 0;
  left: 47px;
  right: 0;
  bottom: 0;
  display: block;
  box-sizing: border-box;
  padding: 7px 12px 0;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  font-size: 85%;
  line-height: 18px;
     color: #00A6BA;
  pointer-events: none;
  transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}

/* Underline */
.pure-material-textfield-filled > input + span::after,
.pure-material-textfield-filled > textarea + span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 2px;
  background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  transform-origin: bottom center;
  transform: scaleX(0);
  transition: transform 0.3s;
}

/* Hover */
.pure-material-textfield-filled > input:hover,
.pure-material-textfield-filled > textarea:hover {
  /* border-bottom-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87); */
  background-color: #fff;
  border-color: #00A6BA;
}
.pure-material-textfield-filled > input:hover + span + svg{
  color: #00A6BA;
}

/* Placeholder-shown */
.pure-material-textfield-filled > input:not(:focus):placeholder-shown + span,
.pure-material-textfield-filled > textarea:not(:focus):placeholder-shown + span {
  font-size: inherit;
      line-height: 48px;
    color: #737E80;
 font-size:18px;
}
.pure-material-textfield-filled > input::placeholder {
  opacity: 0;
}
.pure-material-textfield-filled > input:focus::placeholder {
  opacity: 1;
  font-weight: 500;
}
/* Focus */
.pure-material-textfield-filled > input:focus,
.pure-material-textfield-filled > textarea:focus {
  outline: none;
  background: #fff;
  border-color: #00A6BA;
    box-shadow: 2px 2px 2px 0px #D2DFFA, -2px -2px 2px 0px #D2DFFA, -2px 2px 2px 0px #D2DFFA, 2px -2px 2px 0px #D2DFFA;
}
.pure-material-textfield-filled > input:focus + span + svg{
  color: #00A6BA;
}
.pure-material-textfield-filled > input:focus + span,
.pure-material-textfield-filled > textarea:focus + span {
  /* color: rgb(var(--pure-material-primary-rgb, 33, 150, 243)); */
}

.pure-material-textfield-filled > input:focus + span::before,
.pure-material-textfield-filled > textarea:focus + span::before {
  opacity: 0.12;
}

.pure-material-textfield-filled > input:focus + span::after,
.pure-material-textfield-filled > textarea:focus + span::after {
  /* transform: scale(1); */
}

/* Disabled */
.pure-material-textfield-filled > input:disabled,
.pure-material-textfield-filled > textarea:disabled {
  border-bottom-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.24);
}

.pure-material-textfield-filled > input:disabled + span,
.pure-material-textfield-filled > textarea:disabled + span {
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

/* Faster transition in Safari for less noticable fractional font-size issue */
@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
      .pure-material-textfield-filled > input,
      .pure-material-textfield-filled > input + span,
      .pure-material-textfield-filled > input + span::after,
      .pure-material-textfield-filled > textarea,
      .pure-material-textfield-filled > textarea + span,
      .pure-material-textfield-filled > textarea + span::after {
          transition-duration: 0.1s;
      }
  }
}
.pure-material-textfield-filled .amount_hint{
  font-size: 13px;
  color: #737E80;
  margin: 8px 0 0;
}
.form_submit{
padding: 14px 10px 14px 10px;
align-items: center;
border-radius: 50px;
background: #00A6BA;
color: #fff;
border:none;
width:100%;
text-align: center;
margin-bottom: 14px;
  margin-top:10px;
 transition:.4s all ease;
}
.form_submit:hover{
  background: #0085FF;
}
.form_submit .submit_underline{
 text-decoration:underline;
}
.form_submit .submit_blur{
 opacity:.6;
}
.form_submit svg{
 transition:.8s all ease;
}
.form_submit:hover svg{
 transform:translateX(10px)
}
.form_concent{
  text-align: center;
  color: #737980;
  text-align: center;
  font-size: 14px;
  font-style: normal;
font-weight: 400;
line-height: 154%; /* 18.2px */
letter-spacing: 0.13px;
margin-bottom: 0;
}
.form_concent span{
  color: #00A6BA;
  font-weight: 600;
 text-decoration:underline;
 cursor:pointer;
 
}
.page_user_wrapper{
  padding: 4.5rem 0 4.5rem;
}
.page_user_image{}
.page_user_image img{
  max-width: 90%;
}
.page_user_content{}
.page_user_content h3{
  color: #1F3133;
font-size: 58px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 69.6px */
letter-spacing: -1.45px;
margin-bottom: 20px;
}
.page_user_content h3 span{
  color: #00A6BA;
}
.page_user_content p{
  color: #737E80;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 25.2px */
letter-spacing: -0.09px;
margin-bottom: 40px;
}
.page_user_content p span{
  /* font-weight: bold; */
 color:#1F3133;
}
.page_user_content a, .request_btn {
  padding: 18px 24px 18px 32px;
  align-items: center;
  border-radius: 50px;
  font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 120%;
  background: #00A6BA;
  color: #fff;
  border:none;
  /* width:100%; */
  text-align: center;
  margin-bottom: 14px;
   transition:.4s all ease;
   text-decoration: none;
   box-shadow: 0px 4px 12px 0px rgba(31, 49, 51, 0.16);
  }
  .page_user_content a:hover, .request_btn:hover{
    background: #0085FF;
  }
  .page_user_content a .submit_underline, .request_btn .submit_underline{
   text-decoration:underline;
  }
  .page_user_content a .submit_blur, .request_btn .submit_blur{
   opacity:.6;
  }
  .page_user_content a svg, .request_btn svg{
   transition:.8s all ease;
  }
  .page_user_content a:hover svg, .request_btn:hover svg{
   transform:translateX(10px)
  }
.request_loan_wrapper{
  padding: 4.5rem 0 4.5rem;
  background: linear-gradient(105deg, #E8FDFF 0%, #FAF6FF 100%);
}
.request_loan_content{

}
.request_loan_content h3{
  color: #1F3133;
font-size: 58px;
font-style: normal;
font-weight: 600;
line-height: 120%;
letter-spacing: -1.45px;
margin-bottom: 20px;
}
.request_loan_content h3 span{
  color: #00A6BA;
}
.request_loan_content p{
color: #737E80;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 140%;
letter-spacing: -0.09px;
}
.request_loan_content p span{
  /* font-weight: bold; */
 color: #1F3133;
}
.request_loan_box{
  display: flex;
  flex-wrap: wrap;
}
.request_loan_item{
display: flex;
flex-wrap: wrap;
width: calc(50% - 40px);
border-radius: 12px;
border: 1px solid #F0F2F5;
background: #FFF;
box-shadow: 0px 2px 4px 0px rgba(131, 126, 255, 0.08), 0px 4px 12px 0px rgba(131, 126, 255, 0.10);
margin-right: 0;
margin-bottom: 36px;
height: 158px;
color: #00A6BA;
text-decoration: none;
padding: 24px 20px;
vertical-align: top;
  flex-direction: column;
justify-content: space-between;
transition: .8s all ease;
}
.request_loan_item:nth-child(2n+1){
  margin-right: 36px;
}
.request_loan_item:hover{
  transform: translateY(-4px);
}
.request_loan_item h6{
  color: #00A6BA;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 28px */
letter-spacing: -0.15px;
word-break:break-word; 
}
.request_loan_item svg{
}
.finding_loan_wrapper{
  padding: 4.5rem 0 4.5rem;
}
.finding_loan_items{
  padding-right: 7%;
}
.finding_loan_item{
  margin-bottom: 48px;
}
.finding_loan_item:last-child{
  margin-bottom: 0;
}
.finding_loan_item svg{
  color: #00A6BA;
  margin-bottom: 20px;
}
.finding_loan_item h6{
  color: #1F3133;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 28px */
letter-spacing: -0.15px;
margin-bottom: 12px;
}
.finding_loan_item p{
  color: #737E80;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 24px */
margin-bottom: 0;
}
.finding_loan_content{}
.finding_loan_content h3{
  color: #1F3133;
font-size: 58px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 69.6px */
letter-spacing: -1.45px;
 margin-bottom:20px;
}
.finding_loan_content h3 span{
  color: #00A6BA;
}
.finding_loan_content p {
  color: #737E80;
/* Body- 18/400 */
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 25.2px */
letter-spacing: -0.09px;
 margin-bottom:40px;
}
.finding_loan_content p span{
  color: #1F3133;
}
.faq_wrapper{
  padding: 4.5rem 0 4.5rem;
}
.faq_wrapper .faq_head h3{
  color: #1F3133;
font-size: 58px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 69.6px */
letter-spacing: -1.45px;
}
.faq_wrapper .faq_head h3 span{
  color: #00A6BA;
}
.faq_wrapper .accordion{

}
.faq_wrapper .accordion-item{
  margin-bottom: 12px;  
}
.faq_wrapper .accordion-item:hover{
  box-shadow: 0px 4px 12px rgba(115, 115, 128, 0.06);
}
.faq_wrapper .accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.faq_wrapper  .accordion-item:not(:first-of-type){
  border-top: 1px;
  border-top: 1px solid #dee2e6;

}
.faq_wrapper .accordion-button{
  background: #f5f5f5;
  color: #1C1C1F;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 28px */
letter-spacing: -0.15px;
}
.faq_wrapper .accordion-button:hover{
  background: #fff;
  color: #00A6BA;
}
.faq_wrapper .accordion-button:not(.collapsed) {
  background: #fff;
  color: #00A6BA;
  box-shadow: none;

}
.faq_wrapper .accordion-button p{
 margin-bottom:0;
}
.faq_wrapper .accordion-body {
  color: #1C1C1F;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 130%; /* 26px */
}
.faq_wrapper .accordion-button::after{

background-image: url('./../public/static/assets/images/plus.svg');
background-repeat: no-repeat;
}
.faq_wrapper .accordion-button:not(.collapsed)::after{
  background-image: url('./../public/static/assets/images/minus.svg');
  background-repeat: no-repeat;
}
.page_cta_wrapper {
  text-align: center;
  padding: 4.5rem 0 4.5rem;
  background: linear-gradient(105deg, #E0F4F0 0.01%, #E3F2FF 100%);
}
.page_cta_wrapper h3 {
color: #1F2933;
text-align: center;
font-size: 64px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 76.8px */
letter-spacing: -1.6px;
max-width: 850px;
margin: auto;
margin-bottom: 20px;
}
.page_cta_wrapper h3 span{
  color: #00A6BA;
}
.page_cta_wrapper p {
color: #1F2933;
text-align: center;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 130%; /* 26px */
letter-spacing: -0.4px;
margin-bottom: 72px;
}
.page_cta_wrapper a {

}

.footer_wrapper{
  color:#fff;
  padding: 4.5rem 0 4.5rem;
  background: #1F3133;
}
.footer_wrapper .f_logo{
margin-bottom: 12px;
}
.footer_wrapper p{
  margin-bottom: 32px;
  max-width: 380px;
}
.footer_link h4{
  margin-bottom: 15px;
}
.footer_link ul{
  padding-left: 0;
  list-style: none;
}
.footer_link ul li{}
.footer_link ul li a{
  color: #fff;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.60);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: block;
margin-bottom: 12px;
}
.footer_img_wrapper{}
.footer_img_wrapper img{
  margin-right: 20px;

}
.footer_text{
  text-align: center;
  padding: 40px 0 110px;
     color: rgba(31, 49, 51, 0.60);
}
.footer_text p{
  margin-bottom: 0;
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 128.571% */
letter-spacing: 0.5px;
}
.content_page_wrapper{
  padding: 7rem 0 5rem;

}
.page_breadcrumb{
padding-left: 0;
margin-bottom: 32px;
}
.page_breadcrumb li{
display: inline-block;
color: #212623;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.36px;
}
.page_breadcrumb li a{   
  color: rgba(33, 38, 35, 0.38);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.36px;
  text-decoration: none;
}
.page_breadcrumb li svg{
  vertical-align: text-bottom;
}
.content_page_wrapper h1{
  color: #212623;
font-size: 64px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 76.8px */
letter-spacing: -1.5px;
margin-bottom: 40px;
}
.content_page_wrapper p{
  color: #212623;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
}
.footer_stickbar_wrapper{
  background: #fff;
  padding: 14px 0 10px;
  box-shadow: inset 5px 4px 10px rgba(50, 50, 50, 0.14);
  width: 100%;
  position:fixed;
  bottom: -400px;
  transition: .4s all ease;
}
.footer_stickbar_wrapper.active_div{
position:fixed;
bottom: 0;
width: 100%;
}
.footer_stickbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer_stickbar h4{
  margin-bottom: 0;
  font-size: 22px;
}
.footer_stickbar .request_btn{
  margin-bottom: 0;
 padding: 15px 24px 15px 32px;
     font-size: 16px;
}
@media (max-width: 1199px) {
}
@media only screen and (min-width: 992px) and (max-width: 1199px){
  .page_banner_wrapper .page_banner_form{
    padding: 32px;
  }
  .page_banner_wrapper .page_banner_form h1{
    font-size: 36px;
  }
  .page_user_content h3{
    font-size: 36px;
  }
  .request_loan_content h3{
    font-size: 36px;
  }
  .request_loan_item h6{
    font-size: 15px;
  }
  .finding_loan_content h3{
    font-size: 36px;
  }
  .faq_wrapper .faq_head h3{
    font-size: 36px;
  }
  .page_cta_wrapper h3{
    font-size: 36px;
  }
}
@media screen and (max-width: 991px) {
  .page_banner_wrapper .page_banner_form{
    margin-bottom: 50px;
  }
  .page_banner_wrapper{
    padding: 7rem 0 0rem;
  }
  .page_banner_content{
margin-bottom: 50px;
  }
  .page_user_content h3{

  }
  .footer_logo_div{
    margin-bottom: 35px;
  }
  .footer_link{
    margin-bottom: 35px;
  }
}
@media screen and (max-width: 767px) {
  .page_banner_wrapper{
    padding: 7rem 0 0rem;
  }
  .footer_stickbar{
    justify-content: center;
  }
  .footer_stickbar h4{
    display: none;
  }
  .page_banner_wrapper .page_banner_form{
    padding: 30px 24px;
  }
  .page_banner_wrapper .page_banner_form h1{
    font-size: 34px;
  }
  .page_banner_content h3{
    font-size: 36px;
  }
  .page_user_wrapper{
    padding: 3rem 0 2rem;
  }
  .page_user_image{
    margin-bottom: 40px;
  }
  .page_user_content{
    margin-bottom: 40px;
  }
    .page_user_content h3{
      font-size: 36px;
  }
  .request_loan_wrapper{
    padding: 2.5rem 0 2.5rem;
  }
  .request_loan_content h3{
    font-size: 36px;
  }
  .request_loan_item{
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    height:150px;
  }
  .request_loan_item h6{
    font-size: 16px;   
  }
  .request_loan_item:nth-child(2n+1){
    margin-right: 10px;
  }
  .finding_loan_wrapper{
    padding: 4.5rem 0 1.5rem;
  } 
  .finding_loan_items{
    margin-bottom: 30px;
  }
  .finding_loan_content{
    margin-bottom: 30px;
  }
  .finding_loan_content h3{
    font-size: 36px;
  }
  .faq_wrapper{
    padding: 2.5rem 0 2.5rem;
  }
  .faq_wrapper .faq_head h3{
    font-size: 36px;
    margin-bottom: 30px;
  }
  .page_cta_wrapper{
    padding: 2rem 0 2rem;
  }
  .page_cta_wrapper h3{
    font-size: 36px;
  }
  .page_cta_wrapper p{
    margin-bottom: 30px;
  }
  .footer_logo_div{
    margin-bottom: 35px;
  }
  .footer_link{
    margin-bottom: 35px;
  }
  .content_page_wrapper{
    padding: 7rem 4% 5rem;
  }
  .content_page_wrapper h1{
    font-size: 32px;  
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 576px){
  .header nav{
    padding: 10px 0 10px;
  }
  header.header nav ul li a{
    font-size: 24px;
    font-weight: 700;
  }
  .header nav img{
    width: 180px;
  }
  .page_banner_wrapper{
    padding: 0rem 0 0rem;
  }
  .page_banner_wrapper .page_banner_form h1{
    font-size: 30px;
    line-height: 120%;
    margin-bottom: 24px;
  }
  .page_banner_wrapper .page_banner_form{
    padding: 30px 18px;
    padding-top: 6rem;
    margin-bottom: 0;
    box-shadow: none !important;
  }
  .page_banner_wrapper .form_grid{
    box-shadow: 0 8px 6px -6px #ddd;
    padding: 0;
    margin-bottom: 50px;
  }
  .pure-material-textfield-filled{
    margin-bottom: 18px;
  }
  .page_banner_content h3{
    font-size: 36px;
    margin-bottom: 30px;
  }
  .footer_stickbar_wrapper{
    padding: 8px 0 8px;
  }
  .footer_stickbar{
    justify-content: center;
  }
  .footer_stickbar h4{
    display: none;
  }
  .finding_loan_content h3{
    font-size: 32px;
  }
  .faq_wrapper .faq_head h3{
    font-size: 32px;
  }
}
@media screen and (max-width: 400px){
  .header nav img{
    width: 160px;
  }
  .page_banner_wrapper .page_banner_form h1{
    font-size: 30px;
  }
  .request_loan_item{
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    width: calc(50% - 20px);
  }
  .request_loan_item:nth-child(2n+1){
    margin-right: 10px;
  }
}